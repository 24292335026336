require( './bootstrap' );

// Import jQuery
import 'jquery';

// Import jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

// Import jQuery UI widgets
import 'jquery-ui/ui/widgets/datepicker.js';

// Import select2
import 'select2/dist/js/select2.min.js';

// Import input-mask-phone-number
import './jquery-input-mask-phone-number.js';

// Import bootstrap
import 'bootstrap';

// Import Fontawesome
require( '@fortawesome/fontawesome-free/js/fontawesome.min' );

// Chart.js
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register( zoomPlugin );
window.Chart = Chart;
